import React from "react";

export default function Title(props) {
  return (
    <div className={"container mx-auto " + props.containerStyle}>
      {/* <h2
        className={
          "absolute w-full max-w-1/2 opacity-10 transform translate-x-0.5 translate-y-10 " +
          props.textStyle
        }>
        {props.text}
      </h2> */}
      <h2 className={"static font-title " + props.textStyle}>{props.text}</h2>
    </div>
  );
}
  