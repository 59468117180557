import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { OutboundLink } from "gatsby-plugin-google-gtag";

import Title from "../text/title";
import Highlight from "../text/highlight";

export default function Partners() {
  return (
    <section id="partners" className="bg-background1 py-10">
      <Title
        text={"Partners"}
        textStyle={"font-title text-6xl md:text-7xl lg:text-9xl"}
        containerStyle={"mx-auto  p-10 text-white"}
      />

      <div className="container lg:flex mx-auto">
        <p className="font-main lg:w-1/2 my-auto text-white text-3xl px-8 leading-loose">
          We work with <Highlight text={"Startups, "} />
          <Highlight text={"Agencies, "} />
          <Highlight text={"Semi-State Bodies, "} />
          and{" "}
          <Highlight text={"Non-Profits "} />to deliver results &amp; building
          lasting partnerships.
        </p>

        <div className="container grid grid-cols-2 gap-2 m-auto max-w-lg">
          <div className="p-10 rounded-lg max-w-xs object-center m-auto">
            <OutboundLink
              href="https://move-dungarvan.ie"
              target="_blank"
              rel="noreferrer">
              <StaticImage src="../../images/partner/move.svg" alt="MOVE Dungarvan Logo" />
            </OutboundLink>
          </div>
          <div className="p-10 rounded-lg max-w-xs m-auto">
            <OutboundLink href="https://www1.vhi.ie/" target="_blank" rel="noreferrer">
              <StaticImage src="../../images/partner/vhi.webp" alt="VHI Logo" />
            </OutboundLink>
          </div>
          <div className="p-10 rounded-lg max-w-xs m-auto">
            <OutboundLink href="https://solasnamara.ie" target="_blank" rel="noreferrer">
              <StaticImage src="../../images/partner/solas.webp" alt="Solas Na Mara Logo" />
            </OutboundLink>
          </div>
          <div className="p-10 rounded-lg max-w-xs m-auto">
            <OutboundLink
              href="https://www.corkmidsummer.com/"
              target="_blank"
              rel="noreferrer">
              <StaticImage src="../../images/partner/cork-midsummer.webp" alt="Cork Midsummer Festival Logo" />
            </OutboundLink>
          </div>
        </div>
      </div>
    </section>
  );
}
